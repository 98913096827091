import React from 'react';

import HeroSection404 from './Sections/Hero/Hero';

const Page404 = ({}) => {
  return (
    <>
      <HeroSection404 />
    </>
  );
}

Page404.propTypes = {};

export default Page404;
