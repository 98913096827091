import React from 'react';

import { useSiteMetadata } from '../services/Queries/DataQueries';
import { useImages } from '../services/Queries/ImageQueries';

import MainLayout from '../components/Misc/Layout/MainLayout';
import Page404 from '../components/Pages/404Page/404Page';

const NotFoundPage = () => {
  const siteMetadata = useSiteMetadata();
  const { openGraphImage } = useImages();

  return (
    <MainLayout
      meta={{ pageTitle: '404', ...siteMetadata, openGraphImage: openGraphImage.childImageSharp.fixed.src }}
      mainNav={siteMetadata.mainNav}
    >
      <Page404 />
    </MainLayout>
  );
}

export default NotFoundPage;


