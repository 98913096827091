import React from 'react';

import { useImages } from '../../../../../services/Queries/ImageQueries';

import { Container, Title } from '../../../../Misc/Styled/Styled';
import {
  TitleWrapper, ContentWrapper, ContentColumn, Page404HeroSectionStyled, HeroImagesWrapper, Page404HeroChickenImage,
  Page404HeroBasketWithEggsImage, HomePageLink,
} from './HomeHeroStyled';

const HeroSection404 = () => {
  const { grassFieldBg, chicken, basketWithEggs } = useImages();
  return (
    <Page404HeroSectionStyled
      Tag={'section'}
      fluid={grassFieldBg.childImageSharp.fluid}
    >
      <Container>
        <TitleWrapper>
          <Title blank>
            <Title as={'span'} alike={'h3'} fontWeight={'bold'}>404: Страница није пронађена</Title>
            <HomePageLink to={'/'}>Иди на почетну страну</HomePageLink>
          </Title>
        </TitleWrapper>
        <ContentWrapper>
          <ContentColumn>
            <HeroImagesWrapper>
              <Page404HeroChickenImage fluid={chicken.childImageSharp.fluid} />
              <Page404HeroBasketWithEggsImage fluid={basketWithEggs.childImageSharp.fluid} />
            </HeroImagesWrapper>
          </ContentColumn>
        </ContentWrapper>
      </Container>
    </Page404HeroSectionStyled>
  );
}

export default HeroSection404;
